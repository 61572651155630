import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/CapillaryMicroglia.module.css';
import GroupedByAutocomplete from './GroupBy';
import GeneAutocomplete from '../components/gene';
import axios from 'axios';


function Capillary() {

  const [submitClicked, setSubmitClicked] = useState(false);
  const [encodedImage, setEncodedImage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(null);
  
  const handleSubmission = () => {
    setSubmitClicked(true);
  };


  // State for sex checkboxes
  const [sexCheckboxes, setSexCheckboxes] = useState({
    M: false,
    F: false,
  });


  // Handler function for updating the checked status of sex checkboxes
  const handleSexCheckboxChange = (sex) => {
    setSexCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [sex]: !prevCheckboxes[sex],
    }));
  };

  // State for disease checkboxes
  const [diseaseCheckboxes, setDiseaseCheckboxes] = useState({
    YngCtrl: false,
    OldCtrl: false,
    AD: false,
    ALS: false,
    FTD: false,
  });

  // Handler function for updating the checked status of disease checkboxes
  const handleDiseaseCheckboxChange = (disease) => {
    setDiseaseCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [disease]: !prevCheckboxes[disease],
    }));
  };

  const [leidenCheckboxes, setLeidenCheckboxes] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  });

  // Handler function for updating the checked status of disease checkboxes
  const handleLedienCheckboxChange = (leiden) => {
    setLeidenCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [leiden]: !prevCheckboxes[leiden],
    }));
  };


  const [inciteSeqCheckboxes, setInciteSeqCheckboxes] = useState({
    nfkb: {
      'NFKB_nCLR_H3_92to100': false,
      'NFKB_nCLR_H3_75to92': false,
      'NFKB_nCLR_H3_50to75': false,
      'NFKB_nCLR_H3_25to50': false,
      'NFKB_nCLR_H3_0to25': false
    },
    betaCatenin: {
      'BCATENIN_nCLR_H3_90to100': false,
      'BCATENIN_nCLR_H3_75to90': false,
      'BCATENIN_nCLR_H3_50to75': false,
      'BCATENIN_nCLR_H3_25to50': false,
      'BCATENIN_nCLR_H3_0to25': false
    },
    tdp43: {
      'TDP_nCLR_H3_90to100': false,
      'TDP_nCLR_H3_75to90': false,
      'TDP_nCLR_H3_50to75': false,
      'TDP_nCLR_H3_25to50': false,
      'TDP_nCLR_H3_0to25': false
    }
  });
  
  
  const handleInciteSeqCheckboxChange = (category, checkbox) => {
    setInciteSeqCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [category]: {
        ...prevCheckboxes[category],
        [checkbox]: !prevCheckboxes[category][checkbox]
      }
    }));
  };

  const [selectedGeneName, setSelectedGeneName] = useState('');

  const handleGeneNameChange = (value) => {
    setSelectedGeneName(value);
  };

  const [selectedGroupBy, setSelectedGroupBy] = useState('');

  const handleGroupBySelect = (value) => {
    setSelectedGroupBy(value);
  };

  useEffect(() => {
    if (submitClicked) {
      setLoading(true);
      setErrorMessage(null);
  
      const requestData = {
        sexCheckboxes,
        diseaseCheckboxes,
        leidenCheckboxes,
        inciteSeqCheckboxes,
        selectedGeneName,
        selectedGroupBy,
      };
  
      console.log(requestData);
      // Make a POST request to Flask API endpoint with checkbox values
      axios.post('https://murphylabvasculardata.cam.uchc.edu/api/capillary', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log(response);
        console.log(response.data);
        setEncodedImage(response.data.graph);
        setErrorMessage(response.data.Error);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
        setSubmitClicked(false);
      });
    }
  }, [submitClicked, sexCheckboxes, diseaseCheckboxes, inciteSeqCheckboxes, leidenCheckboxes, selectedGeneName, selectedGroupBy]);
  return (
    <>
      <div className={styles.checkboxcontainer}>
        <div className={styles.row1}>
          <div className={styles.preptypecontainer}>
          </div>
          <div className={styles.sexcontainer}>
            <div className={styles.sex}>
              Sex
              <div className={styles.checkboxList}>
                <label>
                  <input 
                    type="checkbox" 
                    checked={sexCheckboxes.M}
                    onChange={() => handleSexCheckboxChange('M')}
                  />
                  Male
                </label>
                <label>
                  <input 
                    type="checkbox" 
                    checked={sexCheckboxes.F}
                    onChange={() => handleSexCheckboxChange('F')}
                  />
                  Female
                </label>
              </div>
            </div>
          </div>
          <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              Disease Group
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.YngCtrl}
                    onChange={() => handleDiseaseCheckboxChange('YngCtrl')}
                  />
                  Healthy Young (Yng)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.OldCtrl}
                    onChange={() => handleDiseaseCheckboxChange('OldCtrl')}
                  />
                  Healthy Old (Old)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.AD}
                    onChange={() => handleDiseaseCheckboxChange('AD')}
                  />
                  Alzheimer's Disease (AD)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.ALS}
                    onChange={() => handleDiseaseCheckboxChange('ALS')}
                  />
                  Amyotrophic Lateral Sclerosis (ALS)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.FTD}
                    onChange={() => handleDiseaseCheckboxChange('FTD')}
                  />
                  Frontotemporal Dementia (FTD)
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row3}> 
          <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              NFkB
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.nfkb['NFKB_nCLR_H3_92to100']}
                    onChange={() => handleInciteSeqCheckboxChange('nfkb', 'NFKB_nCLR_H3_92to100')}                  
                  />
                  100% to 92%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.nfkb['NFKB_nCLR_H3_75to92']}
                    onChange={() => handleInciteSeqCheckboxChange('nfkb', 'NFKB_nCLR_H3_75to92')}                  
                  />
                  92% to 75%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.nfkb['NFKB_nCLR_H3_50to75']}
                    onChange={() => handleInciteSeqCheckboxChange('nfkb', 'NFKB_nCLR_H3_50to75')}                  
                  />
                  75% to 50%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.nfkb['NFKB_nCLR_H3_25to70']}
                    onChange={() => handleInciteSeqCheckboxChange('nfkb', 'NFKB_nCLR_H3_25to50')}                  
                  />
                  50% to 25%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.nfkb['NFKB_nCLR_H3_0to25']}
                    onChange={() => handleInciteSeqCheckboxChange('nfkb', 'NFKB_nCLR_H3_0to25')}                  
                  />
                  25% to 0%
                </label>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              β-catenin
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.betaCatenin['BCATENIN_nCLR_H3_90to100']}
                    onChange={() => handleInciteSeqCheckboxChange('betaCatenin', 'BCATENIN_nCLR_H3_90to100')}
                  />
                  100% to 90%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.betaCatenin['BCATENIN_nCLR_H3_75to90']}
                    onChange={() => handleInciteSeqCheckboxChange('betaCatenin', 'BCATENIN_nCLR_H3_75to90')}
                  />
                  90% to 75%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.betaCatenin['BCATENIN_nCLR_H3_50to75']}
                    onChange={() => handleInciteSeqCheckboxChange('betaCatenin', 'BCATENIN_nCLR_H3_50to75')}
                  />
                  75% to 50%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.betaCatenin['BCATENIN_nCLR_H3_25to50']}
                    onChange={() => handleInciteSeqCheckboxChange('betaCatenin', 'BCATENIN_nCLR_H3_25to50')}
                  />
                  50% to 25%
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={inciteSeqCheckboxes.betaCatenin['BCATENIN_nCLR_H3_0to25']}
                    onChange={() => handleInciteSeqCheckboxChange('betaCatenin', 'BCATENIN_nCLR_H3_0to25')}
                  />
                  25% to 0%
                </label>
              </div>
            </div>
          </div>
          <div className={styles.diseasegroupcontainer}>
          <div className={styles.diseasegroup}>
            TDP-43
            <div className={styles.checkboxList}>
              <label>
                <input
                  type="checkbox"
                  checked={inciteSeqCheckboxes.tdp43['TDP_nCLR_H3_90to100']}
                  onChange={() => handleInciteSeqCheckboxChange('tdp43', 'TDP_nCLR_H3_90to100')}
                />
                100% to 90%
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={inciteSeqCheckboxes.tdp43['TDP_nCLR_H3_75to90']}
                  onChange={() => handleInciteSeqCheckboxChange('tdp43', 'TDP_nCLR_H3_75to90')}
                />
                90% to 75%
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={inciteSeqCheckboxes.tdp43['TDP_nCLR_H3_50to75']}
                  onChange={() => handleInciteSeqCheckboxChange('tdp43', 'TDP_nCLR_H3_50to75')}
                />
                75% to 50%
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={inciteSeqCheckboxes.tdp43['TDP_nCLR_H3_25to50']}
                  onChange={() => handleInciteSeqCheckboxChange('tdp43', 'TDP_nCLR_H3_25to50')}
                />
                50% to 25%
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={inciteSeqCheckboxes.tdp43['TDP_nCLR_H3_0to25']}
                  onChange={() => handleInciteSeqCheckboxChange('tdp43', 'TDP_nCLR_H3_0to25')}
                />
                25% to 0%
              </label>
              </div>
            </div>
          </div>
          <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              Leiden
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[0]}
                    onChange={() => handleLedienCheckboxChange(0)}
                  />
                  0
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[1]}
                    onChange={() => handleLedienCheckboxChange(1)}
                  />
                  1
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[2]}
                    onChange={() => handleLedienCheckboxChange(2)}
                  />
                  2
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[3]}
                    onChange={() => handleLedienCheckboxChange(3)}
                  />
                  3
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[4]}
                    onChange={() => handleLedienCheckboxChange(4)}
                  />
                  4
                </label>
              </div>
            </div>
          </div>
        <div>
          <div className={styles.column1}>
            <div className={styles.plot}>
              Plot
            </div>
            <GeneAutocomplete onChange={handleGeneNameChange}></GeneAutocomplete>
          </div>
        </div>
        <div className={styles.column1}>
          <div className={styles.groupedby}>
            Group by
          </div>
          <GroupedByAutocomplete onChange={handleGroupBySelect}></GroupedByAutocomplete>
        </div>
        <div className={styles.graphtitle}>Graph
          <div className={styles.graph}>
            {loading ? (
              <div className={styles.loader}></div>
            ) : (
              encodedImage && <img src={`data:image/png;base64,${encodedImage}`} alt="Encoded" />
            )}
          </div>
          <div>
            {errorMessage}
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmission}
        className={styles.submit}
        disabled={loading}
      >
        Submit
      </button>
      <Link to="/download">
        <button className={styles.redirectButton}>Go to Download Page</button>
      </Link>
    </>
  );  
}

export default Capillary;
