import React, { useState } from 'react';
import '../styles/Group.css';

const GroupedByAutocomplete = ({ onChange }) => {
  const [selectedSearchType, setSelectedSearchType] = useState('');

  const handleCheckboxChange = (searchType) => {
    setSelectedSearchType(searchType);
    onChange(searchType); // Pass the selected searchType to the parent component
  };

  return (
    <div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === 'Sex'}
            onChange={() => handleCheckboxChange('Sex')}
          />
          Sex
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === 'DiseaseState'}
            onChange={() => handleCheckboxChange('DiseaseState')}
          />
          Disease State
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === 'prep_type'}
            onChange={() => handleCheckboxChange('prep_type')}
          />
          Prep Type
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === 'bulk_label'}
            onChange={() => handleCheckboxChange('bulk_label')}
          />
          Cell Type
        </label>
      </div>
    </div>
  );
};

export default GroupedByAutocomplete;
