import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/CapillaryMicroglia.module.css';
import GroupedByAutocomplete from './GroupByAll';
import GeneAutocomplete from '../components/gene';
import axios from 'axios';


function Microglia() {

  const [submitClicked, setSubmitClicked] = useState(false);
  const [encodedImage, setEncodedImage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(null);
  
  const handleSubmission = () => {
    setSubmitClicked(true);
  };

  const [preperationTypeCheckboxes, setpreperationTypeCheckboxes] = useState({
    ['10x3prime_soloTE']: false,
    ['10x3prime_inCITE_soloTE']: false,
  });
  
  
    const handlepreperationTypeCheckboxChange = (checkbox) => {
      setpreperationTypeCheckboxes((prevCheckboxes) => ({
        ...prevCheckboxes,
        [checkbox]: !prevCheckboxes[checkbox],
      }));
    };

  // State for sex checkboxes
  const [sexCheckboxes, setSexCheckboxes] = useState({
    M: false,
    F: false,
  });


  // Handler function for updating the checked status of sex checkboxes
  const handleSexCheckboxChange = (sex) => {
    setSexCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [sex]: !prevCheckboxes[sex],
    }));
  };

  // State for disease checkboxes
  const [diseaseCheckboxes, setDiseaseCheckboxes] = useState({
    YngCtrl: false,
    OldCtrl: false,
    AD: false,
    ALS: false,
    FTD: false,
  });

  // Handler function for updating the checked status of disease checkboxes
  const handleDiseaseCheckboxChange = (disease) => {
    setDiseaseCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [disease]: !prevCheckboxes[disease],
    }));
  };

  const [leidenCheckboxes, setLeidenCheckboxes] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  // Handler function for updating the checked status of disease checkboxes
  const handleLedienCheckboxChange = (leiden) => {
    setLeidenCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [leiden]: !prevCheckboxes[leiden],
    }));
  };


  const [selectedGeneName, setSelectedGeneName] = useState('');

  const handleGeneNameChange = (value) => {
    setSelectedGeneName(value);
  };

  const [selectedGroupBy, setSelectedGroupBy] = useState('');

  const handleGroupBySelect = (value) => {
    setSelectedGroupBy(value);
  };

  useEffect(() => {
    if (submitClicked) {
      setLoading(true);
      setErrorMessage(null);
  
      const requestData = {
        sexCheckboxes,
        preperationTypeCheckboxes,
        diseaseCheckboxes,
        leidenCheckboxes,
        selectedGeneName,
        selectedGroupBy,
      };
  
      console.log(requestData);
      // Make a POST request to Flask API endpoint with checkbox values
      axios.post('https://murphylabvasculardata.cam.uchc.edu/api/microglia', requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log(response);
        console.log(response.data);
        setEncodedImage(response.data.graph);
        setErrorMessage(response.data.Error);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
        setSubmitClicked(false);
      });
    }
  }, [submitClicked, sexCheckboxes, diseaseCheckboxes, preperationTypeCheckboxes, leidenCheckboxes, selectedGeneName, selectedGroupBy]);
  return (
    <>
      <div className={styles.checkboxcontainer}>
        <div className={styles.row1}>
          <div className={styles.preptypecontainer}>
            <div className={styles.preptype}>
                Preparation Type
                <div className={styles.checkboxList}>
                  <label>
                    <input
                     type="checkbox"
                     checked={preperationTypeCheckboxes['10x3prime_soloTE']}
                     onChange={() => handlepreperationTypeCheckboxChange('10x3prime_soloTE')}
                    />
                    3'RNA
                  </label>
                  <label>
                    <input 
                      type="checkbox" 
                      checked={preperationTypeCheckboxes['10x3prime_inCITE_soloTE']}
                      onChange={() => handlepreperationTypeCheckboxChange('10x3prime_inCITE_soloTE')}
                      />
                    3'RNA + inCITE
                  </label>
                </div>
              </div>
          </div>
          <div className={styles.sexcontainer}>
            <div className={styles.sex}>
              Sex
              <div className={styles.checkboxList}>
                <label>
                  <input 
                    type="checkbox" 
                    checked={sexCheckboxes.M}
                    onChange={() => handleSexCheckboxChange('M')}
                  />
                  Male
                </label>
                <label>
                  <input 
                    type="checkbox" 
                    checked={sexCheckboxes.F}
                    onChange={() => handleSexCheckboxChange('F')}
                  />
                  Female
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row3}> 
        <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              Disease Group
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.YngCtrl}
                    onChange={() => handleDiseaseCheckboxChange('YngCtrl')}
                  />
                  Healthy Young (Yng)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.OldCtrl}
                    onChange={() => handleDiseaseCheckboxChange('OldCtrl')}
                  />
                  Healthy Old (Old)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.AD}
                    onChange={() => handleDiseaseCheckboxChange('AD')}
                  />
                  Alzheimer's Disease (AD)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.ALS}
                    onChange={() => handleDiseaseCheckboxChange('ALS')}
                  />
                  Amyotrophic Lateral Sclerosis (ALS)
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={diseaseCheckboxes.FTD}
                    onChange={() => handleDiseaseCheckboxChange('FTD')}
                  />
                  Frontotemporal Dementia (FTD)
                </label>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.diseasegroupcontainer}>
            <div className={styles.diseasegroup}>
              Leiden
              <div className={styles.checkboxList}>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[0]}
                    onChange={() => handleLedienCheckboxChange(0)}
                  />
                  0
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[1]}
                    onChange={() => handleLedienCheckboxChange(1)}
                  />
                  1
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[2]}
                    onChange={() => handleLedienCheckboxChange(2)}
                  />
                  2
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={leidenCheckboxes[3]}
                    onChange={() => handleLedienCheckboxChange(3)}
                  />
                  3
                </label>
              </div>
            </div>
          </div>
        <div>
          <div className={styles.column1}>
            <div className={styles.plot}>
              Plot
            </div>
            <GeneAutocomplete onChange={handleGeneNameChange}></GeneAutocomplete>
          </div>
        </div>
        <div className={styles.column1}>
          <div className={styles.groupedby}>
            Group by
          </div>
          <GroupedByAutocomplete onChange={handleGroupBySelect}></GroupedByAutocomplete>
        </div>
        <div className={styles.graphtitle}>Graph
          <div className={styles.graph}>
            {loading ? (
              <div className={styles.loader}></div>
            ) : (
              encodedImage && <img src={`data:image/png;base64,${encodedImage}`} alt="Encoded" />
            )}
          </div>
          <div>
            {errorMessage}
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmission}
        className={styles.submit}
        disabled={loading}
      >
        Submit
      </button>
      <Link to="/download">
        <button className={styles.redirectButton}>Go to Download Page</button>
      </Link>
    </>
  );  
}

export default Microglia;
