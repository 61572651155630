import React, { useState } from 'react';
import '../styles/Group.css';

const GroupedByAutocomplete = ({ onChange }) => {
  const [selectedSearchType, setSelectedSearchType] = useState('');

  // Define constants for the search types
  const SEARCH_TYPES = {
    SEX: 'Sex',
    DISEASE_STATE: 'DiseaseState',
    PREP_TYPE: 'prep_type',
    BULK_LABEL: 'bulk_label',
    PCT_NFKB_NCLR_H3: 'pct_NFKB_nCLR_H3',
    PCT_BCATENIN_NCLR_H3: 'pct_BCATENIN_nCLR_H3',
    PCT_TDP_NCLR_H3: 'pct_TDP_nCLR_H3'  };

  const handleCheckboxChange = (searchType) => {
    setSelectedSearchType(searchType);
    onChange(searchType); // Pass the selected searchType to the parent component
  };

  return (
    <div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.SEX}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.SEX)}
          />
          Sex
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.DISEASE_STATE}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.DISEASE_STATE)}
          />
          Disease State
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.PREP_TYPE}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.PREP_TYPE)}
          />
          Prep Type
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.BULK_LABEL}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.BULK_LABEL)}
          />
          Cell Type
        </label>
      </div>
      <div className='checkboxList'>
      <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.PCT_NFKB_NCLR_H3}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.PCT_NFKB_NCLR_H3)}
          />
          NFKB
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.PCT_BCATENIN_NCLR_H3}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.PCT_BCATENIN_NCLR_H3)}
          />
          β-catenin
        </label>
      </div>
      <div className='checkboxList'>
        <label>
          <input
            type="checkbox"
            checked={selectedSearchType === SEARCH_TYPES.PCT_TDP_NCLR_H3}
            onChange={() => handleCheckboxChange(SEARCH_TYPES.PCT_TDP_NCLR_H3)}
          />
          TDP-43
        </label>
      </div>
    </div>
  );
};

export default GroupedByAutocomplete;
