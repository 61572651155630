import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/DownloadPage.css';

const DownloadPage = () => {
  const fileNames = [
    'All_HuBr_nuclei_data_concat_soloTE_demux_metadata_scrublet_leiden_celltypes.h5ad',
    'Batch2022Dec22_filtered_feature_bc_matrix.h5',
    'Batch2022Dec2_filtered_feature_bc_matrix.h5',
    'Batch2022Feb23_filtered_feature_bc_matrix.h5',
    'Batch2022Feb2_filtered_feature_bc_matrix.h5',
    'Batch2022Mar15_filtered_feature_bc_matrix.h5',
    'Batch2022Mar16A_filtered_feature_bc_matrix.h5',
    'Batch2022Mar16B_filtered_feature_bc_matrix.h5',
    'Batch2022Mar17_filtered_feature_bc_matrix.h5',
    'Batch2022Mar21_filtered_feature_bc_matrix.h5',
    'Batch2022Mar24A_filtered_feature_bc_matrix.h5',
    'Batch2022Mar24B_filtered_feature_bc_matrix.h5',
    'Batch2022Mar2A_filtered_feature_bc_matrix.h5',
    'Batch2022Mar2B_filtered_feature_bc_matrix.h5',
    'Batch2022Mar8_filtered_feature_bc_matrix.h5',
    'Batch2022Mar9A_filtered_feature_bc_matrix.h5',
    'Batch2022Mar9B_filtered_feature_bc_matrix.h5',
    'Batch2022Oct10_filtered_feature_bc_matrix.h5',
    'Batch2022Oct19_filtered_feature_bc_matrix.h5',
    'Batch2023Jan11_filtered_feature_bc_matrix.h5',
    'Batch2023Jan12_filtered_feature_bc_matrix.h5',
    'Batch2023Jan17_filtered_feature_bc_matrix.h5',
    'Batch2023Jan18_filtered_feature_bc_matrix.h5',
    'Batch2023Jan19A_filtered_feature_bc_matrix.h5',
    'Batch2023Jan19B_filtered_feature_bc_matrix.h5',
    'Batch2023Jan24A_filtered_feature_bc_matrix.h5',
    'Batch2023Jan24B_filtered_feature_bc_matrix.h5',
    'Batch2023Jan25_filtered_feature_bc_matrix.h5',
    'Batch2023Jan9_filtered_feature_bc_matrix.h5',
    'Capillaries_INCITE_object.h5ad',
    'Microglia_INCITE_object.h5ad',
    'All Data_adata_object_processed_2024.h5ad',
  ];

  const handleDownload = (fileName) => {
    const fileUrl = process.env.PUBLIC_URL + '/' + fileName;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='container'>
      <div className='titlecontainer'>
        <div className='title'> Data Download</div>
      </div>
      <div className='linkcontainer'>
        {fileNames.map((fileName, index) => {
          const abbreviatedName = index === 0 ? 'Concatenated.h5ad' : fileName.split('_')[0];
            return (
            <button className='downloadbutton' key={fileName} onClick={() => handleDownload(fileName)}>
              {abbreviatedName}
            </button>
          );
        })}
      </div>
      <Link to="/">
        <button className='homebutton'>Back to Home</button>
      </Link>
    </div>
  );
};

export default DownloadPage;
