import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import '../styles/Group.css';

const GeneNameAutocomplete = ({ onChange }) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchGeneNames = async () => {
      try {
        const response = await fetch('/gene_names.txt'); // Adjust the path accordingly
        const data = await response.text();
        const geneNamesArray = data.split('\n').filter(Boolean);
        setSearchTypes(geneNamesArray);
      } catch (error) {
        console.error('Error fetching gene names:', error);
      }
    };

    fetchGeneNames();
  }, []);

  const [searchTypes, setSearchTypes] = useState([]);

  const getSuggestions = (inputValue) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();
    const filteredTypes = searchTypes.filter(
      (type) => type.toLowerCase().includes(inputValueLowerCase)
    );

    return filteredTypes.slice(0, 5);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
    // Call the onChange prop with the selected search type
    onChange(newValue);
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) => suggestion}
      renderSuggestion={renderSuggestion}
      inputProps={{
        placeholder: 'Type a gene name...',
        value,
        onChange: handleChange,
      }}
    />
  );
};

export default GeneNameAutocomplete;
