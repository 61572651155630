import React, { useState} from 'react';
import styles from '../styles/AllCells.module.css';
import AllCellsComponent from '../components/AllCells'
import CapillaryCellsComponent from '../components/CapillaryCells';
import MicrogliaCellsComponent from '../components/MicrogliaCells';

function Home() {
  const [selectedOption, setSelectedOption] = useState('All cells');

  // Function to handle changes in the selected option
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // Render the selected component based on the selected option
  const renderSelectedComponent = () => {
    switch (selectedOption) {
      case 'All cells':
        return <AllCellsComponent />;
      case 'Capillary cells':
        return <CapillaryCellsComponent />;
      case 'Microglia cells':
        return <MicrogliaCellsComponent />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <div className={styles.container}>
          <div className={styles.column}>
            <div className={styles.header}>inCITE-seq</div>
            <div className={styles.subheader}>endothelial and microglia enriched human cortex</div>
          </div>
          <img className={styles.logo} src={require("../images/logo.png")} alt="Logo" style={{width: '8rem', margin: '10px'}} />
        </div>
        <div>
          <img src={require("../images/Diagram.png")} alt="Diagram" />
        </div>
        <div className={styles.databrowsing}>
          Data Browsing
        </div>
        <div className={styles.dataType}>
          Select a dataset to plot:
        </div>
      </div>
        
      <select className={styles.select} value={selectedOption} onChange={(e) => handleOptionChange(e.target.value)}>
        <option value="All cells">All Cells</option>
        <option value="Capillary cells">Capillary Cells</option>
        <option value="Microglia cells">Microglia Cells</option>
      </select>

      {renderSelectedComponent()}
    </div>
  );
}

export default Home;
